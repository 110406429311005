<template>
  <Toast />
  <ConfirmDialog />
  <div class="surface-section px-1 py-1 md:px-12 lg:px-12 border-2">
    <div class="flex align-items-center flex-column lg:justify-content-center">
      <h2 class="subtitulos mb-2">
        <strong>INFORME DE HISTORIA CLINICA</strong>
      </h2>
      <span
        ><strong style="font-size: 1.2rem"
          >({{ historia_clientes.nombre_cliente }}) - CÓDIGO:
          {{ historia_clientes.cliente_id }}</strong
        ></span
      >
      <span
        ><strong style="font-size: 1.2rem"
          >{{ edadMesesCliente }} ({{
            historia_clientes.fecha_nacimiento ?? "No Registrado"
          }})</strong
        ></span
      >
    </div>
    <TabView class="tabview-custom">
      <TabPanel>
        <template #header>
          <i class="pi pi-check-square"></i>
          <span class="estilo-titulo-tab">General</span>
        </template>
        <div class="grid">
          <div v-if="!isEnfermeria()" class="color-linea text-center">
            <span style="font-size: 12px; font-weight: bold">HISTORICO</span>
            <div
              v-for="(item, index) in fechas"
              :key="index"
              @click="fechaSeleccionada(item.value)"
              :class="
                item.value == this.historia_clientes.fecha ? 'bg-info' : ''
              "
              v-tooltip.top="item.name"
              style="border: 1px solid #ccc; border-top: none; cursor: pointer"
            >
              {{ item.name.split(" ")[0] }}
            </div>
          </div>
          <div
            :class="
              'ENFERMERIA' != this.auth.user.name
                ? 'col-12 md:col-6'
                : 'col-12 md:col-7'
            "
          >
            <div class="p-fondo color-linea">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-12 text-center">
                  <p>
                    <strong class="subtitulos">DATOS DE LA CONSULTA</strong
                    ><br />
                    <span style="font-size: 15px"
                      ><strong>(Dr(a).:</strong>
                      {{ historia_clientes.nombre_medico ?? "-" }})</span
                    >
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <span
                    ><strong>MOTIVO DE CONSULTA:</strong>
                    <span class="p-invalid">*</span></span
                  >
                  <Textarea
                    v-model="historia_clientes.motivo_consulta"
                    rows="1"
                    v-tooltip.top="'Motivo de Consulta'"
                    :disabled="isEnfermeria()"
                    autoResize
                  />
                  <small class="p-invalid" v-if="errors.motivo_consulta">{{
                    errors.motivo_consulta[0]
                  }}</small>
                </div>
                <div class="field col-12 md:col-6">
                  <span
                    ><strong>EXAMEN F&Iacute;SICO:</strong>
                    <span class="p-invalid">*</span></span
                  >
                  <Textarea
                    v-tooltip.top="'Examen Físico'"
                    v-model="historia_clientes.examen_fisico"
                    rows="1"
                    :disabled="isEnfermeria()"
                    autoResize
                  />
                  <small class="p-invalid" v-if="errors.examen_fisico">{{
                    errors.examen_fisico[0]
                  }}</small>
                </div>
                <div class="field col-12 md:col-12">
                  <span
                    ><strong>DATOS NECESARIOS PARA EL SEDES:</strong>
                    <span class="p-invalid">*</span></span
                  >
                  <Dropdown
                    v-model="historia_clientes.sedes_id"
                    :options="sedes"
                    optionLabel="nombre"
                    optionValue="id"
                    placeholder="Seleccione una Sede"
                    v-tooltip.top="'Seleccione datos para el SEDES'"
                    class="w-full"
                    :filter="true"
                    :disabled="isEnfermeria()"
                  />
                  <small class="p-invalid" v-if="errors.sedes">{{
                    errors.sedes[0]
                  }}</small>
                </div>
                <div class="field col-12 md:col-6">
                  <span
                    ><strong>DIAGN&Oacute;STICO:</strong>
                    <span class="p-invalid">*</span></span
                  >
                  <Textarea
                    v-tooltip.top="'Diagnostico'"
                    v-model="historia_clientes.diagnostico"
                    rows="1"
                    :disabled="isEnfermeria()"
                    autoResize
                  />
                  <small class="p-invalid" v-if="errors.diagnostico">{{
                    errors.diagnostico[0]
                  }}</small>
                </div>
                <div class="field col-12 md:col-6">
                  <span
                    ><strong>CONDUCTA Y TRATAMIENTO:</strong>
                    <span class="p-invalid">*</span></span
                  >
                  <Textarea
                    v-tooltip.top="'Conducta y Tratamiento'"
                    v-model="historia_clientes.conducta"
                    rows="1"
                    :disabled="isEnfermeria()"
                    autoResize
                  />
                  <small class="p-invalid" v-if="errors.conducta">{{
                    errors.conducta[0]
                  }}</small>
                </div>
              </div>
            </div>
            <div class="grid color-linea ml-1 mr-1 mt-2" v-if="!isEnfermeria()">
              <div class="col-12 md:col-6">
                <div class="p-inputgroup">
                  <span style="font-size: 12px; font-weight: bold"
                    ><strong>PRESCRIPCIONES:</strong>
                  </span>
                  <Button
                    v-for="(item, index) in nro_receta"
                    :key="index"
                    @click="verReceta(item.nro_receta)"
                    class="p-button-secondary p-button-sm ml-1"
                    v-tooltip.top="'Ver Receta'"
                  >
                    {{ item.nro_receta }}
                  </Button>
                </div>
              </div>
              <div class="col-12 md:col-6">
                <div class="p-inputgroup">
                  <Button
                    label="PRESCRIPCION"
                    icon="pi pi-plus"
                    class="p-button-info p-button-sm ml-2 mr-2"
                    v-tooltip.top="'Agregar Prescripción'"
                    @click="agregarRecetaNew"
                    :disabled="
                      this.historia_clientes.fecha != this.fecha_seleccionada
                    "
                  />
                  <Button
                    label="IMPRIMIR"
                    icon="pi pi-print"
                    class="p-button-danger p-button-sm"
                    v-tooltip.top="'Imprimir Prescripción'"
                    @click="imprimirReceta()"
                    :disabled="this.recetaSelected == null"
                    :loading="printPrescripcion"
                  />
                </div>
              </div>
              <div class="col-12 md:col-12">
                <DataTable
                  :value="list_prescripciones"
                  class="p-datatable-sm"
                  showGridlines
                >
                  <Column
                    field="cantidad"
                    header="CANT."
                    class="text-center"
                  ></Column>
                  <Column
                    field="nombre_producto"
                    header="PRODUCTO/DESCRIPCION"
                  ></Column>
                  <Column field="indicaciones" header="INDICACIONES"></Column>
                  <Column field="via" header="VIA" class="text-center"></Column>
                </DataTable>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-5">
            <div class="color-linea p-fondo3">
              <p class="subtitulos"><strong>SIGNOS VITALES:</strong></p>
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-6">
                  <span><strong>ATENDIDO POR: </strong></span>
                  <Dropdown
                    v-model="historia_clientes.nombre_enfermera"
                    :options="enfermeras"
                    optionLabel="nombre_completo"
                    optionValue="nombre_completo"
                    :filter="true"
                    placeholder="Seleccione..."
                  >
                  </Dropdown>
                </div>
                <div class="field col-12 md:col-3">
                  <span><strong>GLICEMIA:</strong></span>
                  <InputText
                    placeholder="Ingrese Glicemia"
                    v-model="historia_clientes.glicemias"
                  />
                </div>
                <div class="field col-12 md:col-3">
                  <span
                    ><strong>SpO2:</strong>
                    <span class="p-invalid">*</span> %</span
                  >
                  <InputText
                    placeholder="Ingrese SpO2"
                    v-model="historia_clientes.c02"
                  />
                  <small class="p-invalid" v-if="errors.c02">{{
                    errors.c02[0]
                  }}</small>
                </div>
                <div class="field col-12 md:col-4">
                  <span
                    ><strong>PESO:</strong>
                    <span class="p-invalid">*</span> Kg</span
                  >
                  <InputText
                    placeholder="Ingrese Peso"
                    v-model="historia_clientes.c03"
                    mode="decimal"
                    suffix=" kg"
                    :minFractionDigits="2"
                  />
                  <small class="p-invalid" v-if="errors.c03">{{
                    errors.c03[0]
                  }}</small>
                </div>
                <div class="field col-12 md:col-4">
                  <span><strong>TALLA.: </strong> Cm</span>
                  <InputText
                    placeholder="Ingrese Talla"
                    v-model="historia_clientes.talla"
                  />
                </div>
                <div class="field col-12 md:col-4">
                  <span
                    ><strong>P.A.:</strong>
                    <span class="p-invalid">*</span> mmHg</span
                  >
                  <InputText
                    placeholder="Ingrese P.A."
                    v-model="historia_clientes.c04"
                  />
                  <small class="p-invalid" v-if="errors.c04">{{
                    errors.c04[0]
                  }}</small>
                </div>
                <div class="field col-12 md:col-4">
                  <span
                    ><strong>F.C.:</strong>
                    <span class="p-invalid">*</span> Ipm</span
                  >
                  <InputText
                    placeholder="Ingrese F.C."
                    v-model="historia_clientes.c05"
                  />
                  <small class="p-invalid" v-if="errors.c05">{{
                    errors.c05[0]
                  }}</small>
                </div>
                <div class="field col-12 md:col-4">
                  <span
                    ><strong>TEMP.:</strong>
                    <span class="p-invalid">*</span> °C</span
                  >
                  <InputText
                    placeholder="Ingrese Temp."
                    v-model="historia_clientes.c06"
                  />
                  <small class="p-invalid" v-if="errors.c06">{{
                    errors.c06[0]
                  }}</small>
                </div>
                <div class="field col-12 md:col-4">
                  <span><strong>F. RESPIRATORIA:</strong></span>
                  <InputText
                    placeholder="Ingrese F.R."
                    v-model="historia_clientes.fr"
                  />
                </div>
                <div class="field col-12 md:col-3">
                  <div class="p-inputgroup">
                    <span><strong>ALERGICO?:</strong></span>
                    <Checkbox
                      v-model="check_es_alergico"
                      :binary="true"
                      class="w-full"
                      v-tooltip.top="'Es Alergico'"
                    />
                  </div>
                </div>
                <div class="field col-12 md:col-4" v-show="false">
                  <div class="p-inputgroup">
                    <span><strong class="p-invalid">EMERGENCIA?:</strong></span>
                    <Checkbox
                      v-model="es_emergencia"
                      :binary="true"
                      v-tooltip.top="'Es Emergencia'"
                    />
                  </div>
                </div>
                <div
                  class="field col-12 md:col-12"
                  v-if="check_es_alergico == true"
                >
                  <span><strong>MOTIVO SI ES ALERGICO:</strong></span>
                  <Textarea
                    placeholder="Ingrese Motivo"
                    v-model="historia_clientes.es_alergico"
                    rows="1"
                    autoResize
                  />
                </div>
                <div class="field col-12 md:col-5">
                  <div class="p-inputgroup">
                    <span><strong>T.MEDICAMENTOS?:</strong></span>
                    <Checkbox
                      v-model="check_toma_medicamentos"
                      :binary="true"
                      v-tooltip.top="'Toma Medicamentos'"
                    />
                  </div>
                </div>
                <div
                  class="field col-12 md:col-12"
                  v-if="check_toma_medicamentos == true"
                >
                  <span><strong>MOTIVO SI TOMA MEDICAMENTOS:</strong></span>
                  <Textarea
                    placeholder="Ingrese Motivo"
                    v-model="historia_clientes.toma_medicamentos"
                    rows="1"
                  />
                </div>
                <div class="field col-12 md:col-12 text-center">
                  <p class="subtitulos"><strong>ATENDIDO EN:</strong></p>
                </div>
                <div class="field col-12 md:col-4">
                  <div class="p-inputgroup">
                    <span><strong>REANIMACIÓN?</strong></span>
                    <Checkbox
                      v-model="historia_clientes.reanimacion"
                      :binary="true"
                      v-tooltip.top="'Reanimación'"
                      class="mr-1"
                    />
                  </div>
                </div>
                <div class="field col-12 md:col-4">
                  <div class="p-inputgroup">
                    <span><strong>TRIAJE?</strong></span>
                    <Checkbox
                      v-model="historia_clientes.triaje"
                      :binary="true"
                      v-tooltip.top="'Triaje'"
                      class="mr-1"
                    />
                  </div>
                </div>
                <div class="field col-12 md:col-4">
                  <div class="p-inputgroup">
                    <span><strong>OBSERVACIÓN?</strong></span>
                    <Checkbox
                      v-model="historia_clientes.observacion"
                      :binary="true"
                      v-tooltip.top="'Observación'"
                    />
                  </div>
                </div>
                <div class="field col-12 md:col-4">
                  <div class="p-inputgroup">
                    <span><strong>INTERNACIÓN ?</strong></span>
                    <Checkbox
                      v-model="historia_clientes.internacion"
                      :binary="true"
                      v-tooltip.top="'Internación'"
                      class="mr-1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex md:justify-content-end mt-2">
              <Button
                @click="goBack"
                class="p-button-secondary mr-1"
                v-tooltip.top="'Volver a Ventas'"
              >
                <i class="pi pi-arrow-left"></i>&nbsp;ATR&Aacute;S
              </Button>

              <Button
                label="NO ATENDIDO"
                icon="pi pi-times"
                class="p-button-danger mr-1"
                v-tooltip.top="'No se Atendio'"
                @click="noSeAtendio(historia_clientes.id)"
                :disabled="
                  this.historia_clientes.fecha != this.fecha_seleccionada
                "
              />
              <Button
                icon="pi pi-print"
                class="p-button-sm p-button-warning mr-1"
                v-tooltip.top="'Imprimir Historia'"
                @click="printHistoriaGeneral()"
                :loading="printHistoria"
                :disabled="isEnfermeria()"
              />

              <Button
                label="GUARDAR"
                icon="pi pi-save"
                class="p-button-sm p-button-info"
                v-tooltip.top="'Guardar Historia'"
                @click="guardarHistoria(historia_clientes.id)"
                :loading="loading"
                :disabled="historia_clientes.fecha != this.fecha_seleccionada"
              />
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel :disabled="isEnfermeria()">
        <template #header>
          <div @click="emergenciasMedicas()">
            <i class="pi pi-exclamation-triangle"></i>
            <span class="estilo-titulo-tab">Procedimientos</span>
          </div>
        </template>
        <div v-show="this.datos_emergencias_medicas.cliente_id != null">
          <EmergenciasMedicas
            :datos_emergencias_medicas="datos_emergencias_medicas"
            :emergencias_medicas="emergencias_medicas"
            :medicos="medicos"
          />
        </div>
      </TabPanel>
      <TabPanel :disabled="isEnfermeria()">
        <template #header>
          <div class="custom-tab" @click="activarFormConsentimiento()">
            <i class="pi pi-file mr-1"></i>
            <span class="estilo-titulo-tab">Consentimiento</span>
          </div>
        </template>
        <FormConsentimientoCliente
          :show="formularioConsentimientoDialog"
          :datos_frmprefedinidos="datos_frmprefedinidos"
          :formulario_predefinidos="formulario_predefinidos"
          :cliente_id="historia_clientes.cliente_id"
          :medicos="medicos"
          @actualizarListado="cargarHistoria"
        />
      </TabPanel>
      <TabPanel :disabled="isEnfermeria()">
        <template #header>
          <div @click="getDatosEcoAbdominal()">
            <i class="pi pi-prime"></i>
            <span class="estilo-titulo-tab">Abdominal</span>
          </div>
        </template>
        <div v-show="this.datos_eco_abdominal.cliente_id != null">
          <EcoAbdominal
            :datos_eco_abdominal="datos_eco_abdominal"
            :eco_abdominals_history="eco_abdominals_history"
          />
        </div>
      </TabPanel>
      <TabPanel :disabled="isEnfermeria()">
        <template #header>
          <div @click="getDatosEcoGinecologico()">
            <i class="pi pi-file"></i>

            <span class="estilo-titulo-tab">Ginecol&oacute;gico</span>
          </div>
        </template>
        <div v-show="this.datos_eco_ginecologico.cliente_id != null">
          <EcoGinecologico
            :datos_eco_ginecologico="datos_eco_ginecologico"
            :eco_ginecologicos_history="eco_ginecologicos_history"
          />
        </div>
      </TabPanel>
      <TabPanel :disabled="isEnfermeria()">
        <template #header>
          <div @click="getDatosEcoObstetrico()">
            <i class="pi pi-file"></i>
            <span class="estilo-titulo-tab">Obst&eacute;trico</span>
          </div>
        </template>
        <div v-show="this.datos_eco_obstetrico.cliente_id != null">
          <EcoObstetrico
            :datos_eco_obstetrico="datos_eco_obstetrico"
            :eco_obstetricos_history="eco_obstetricos_history"
          />
        </div>
      </TabPanel>
      <TabPanel :disabled="isEnfermeria()">
        <template #header>
          <div @click="getDatosMonitoroFetal()">
            <i class="pi pi-file"></i>
            <span class="estilo-titulo-tab">Monitoreo Fetal</span>
          </div>
        </template>
        <div v-show="this.datos_monitoreofetal.cliente_id != null">
          <MonitoreoFetal
            :datos_eco_monitoreofetal="datos_monitoreofetal"
            :eco_monitoreofetals_history="eco_monitoreofetals_history"
          />
        </div>
      </TabPanel>
      <TabPanel :disabled="isEnfermeria()">
        <template #header>
          <div @click="mostrar_imagenes(historia_clientes.cliente_id)">
            <i class="pi pi-card"></i>
            <i class="pi pi-images mr-1"></i>
            <span class="estilo-titulo-tab">Foto/Rayos X</span>
          </div>
        </template>
        <ImagenesPacientes
          :show="verImagenes"
          :cliente_id="ClienteImagenes"
          @closeModal="ocultarDialogImg"
          @actualizarListado="cargarHistoria"
        ></ImagenesPacientes>
      </TabPanel>
    </TabView>
    <Receta
      :show="agregarRecetaDialog"
      @closeModal="ocultarDialogReceta"
      :datos_receta="datos_receta"
      @updatePrescripcion="cargarPrescripcion"
    />
  </div>
</template>

<script>
import HistoriaService from "@/service/HistoriaService";
import { useAuth } from "@/stores";
import EcoAbdominal from "@/module/historias/EcoAbdominal.vue";
import Receta from "@/module/historias/AgregarReceta.vue";
import EcoGinecologico from "@/module/historias/EcoGinecologico.vue";
import EcoObstetrico from "@/module/historias/EcoObstetrico.vue";
import MonitoreoFetal from "@/module/historias/MonitoreoFetal.vue";
import ImagenesPacientes from "@/module/plantratamientos/ImagenesPacientes.vue";
import EmergenciasMedicas from "@/module/historias/EmergenciasMedicas.vue";
import FormConsentimientoCliente from "@/module/plantratamientos/FormConsentimientoCliente.vue";

export default {
  components: {
    EcoAbdominal,
    Receta,
    EcoGinecologico,
    EcoObstetrico,
    MonitoreoFetal,
    ImagenesPacientes,
    EmergenciasMedicas,
    FormConsentimientoCliente,
  },
  data() {
    return {
      agregarRecetaDialog: false,
      historia_clientes: [],
      historia_clientes_list: [],
      cliente_id: this.$route.params.id,
      errors: {},
      sedes_id: 30,
      sedes: [],
      estatura: 0,
      enfermeras: [],
      check_toma_medicamentos: false,
      check_es_alergico: false,
      es_emergencia: false,
      loading: false,
      historico_eco_abdominal: [],
      fechas: [
        {
          name: "Cargando...",
          value: "Cargando...",
        },
      ],
      fecha_seleccionada: null,
      prescripciones: [],
      list_prescripciones: [],
      datos_receta: {},
      nro_receta: [],
      cod_informe: null,
      datos_eco_abdominal: {},
      eco_abdominals_history: [],
      datos_eco_ginecologico: {},
      eco_ginecologicos_history: [],
      recetaSelected: null,
      visible: false,
      datos_eco_obstetrico: {},
      eco_obstetricos_history: [],
      datos_monitoreofetal: {},
      eco_monitoreofetals_history: [],
      printPrescripcion: false,
      printHistoria: false,
      nombre_rol_usuario: null,
      verImagenes: false,
      ClienteImagenes: null,
      datos_emergencias_medicas: {},
      emergencias_medicas: [],
      medicos: [],
      datos_frmprefedinidos: [],
      formulario_predefinidos: [],
      formularioConsentimientoDialog: false,
    };
  },
  historiaService: null,
  auth: null,

  created() {
    this.historiaService = new HistoriaService();
    this.auth = useAuth();
  },
  computed: {
    fechaActual() {
      let fecha = new Date();
      let dia = fecha.getDate();
      let mes = fecha.getMonth() + 1;
      let anio = fecha.getFullYear();
      return dia + "/" + mes + "/" + anio;
    },
    edadMesesCliente() {
      let fecha_nacimiento = new Date(this.historia_clientes.fecha_nacimiento);
      let fecha_actual = new Date();
      let edad = fecha_actual.getFullYear() - fecha_nacimiento.getFullYear();
      let meses = fecha_actual.getMonth() - fecha_nacimiento.getMonth();
      if (
        meses < 0 ||
        (meses === 0 && fecha_actual.getDate() < fecha_nacimiento.getDate())
      ) {
        edad--;
        meses += 12;
      }
      return edad + " AÑOS Y " + meses + " MESES";
    },
  },

  mounted() {
    this.cargarHistoria();
  },
  methods: {
    activarFormConsentimiento() {
      this.formularioConsentimientoDialog = true;
    },
    emergenciasMedicas() {
      this.datos_emergencias_medicas = {
        historia_id: this.$route.params.id,
        cliente_id: this.historia_clientes.cliente_id,
        nombre_cliente: this.historia_clientes.nombre_cliente,
        medico_id: this.historia_clientes.medico_id,
        nombre_medico: this.historia_clientes.nombre_medico,
      };
    },
    ocultarDialogImg() {
      this.verImagenes = false;
    },
    mostrar_imagenes(paciente_id) {
      this.ClienteImagenes = parseInt(paciente_id);
      this.verImagenes = true;
    },
    isEnfermeria() {
      if (
        this.auth.user.name === "ENFERMERIA" ||
        this.nombre_rol_usuario === "ENFERMERIA"
      ) {
        return true;
      } else {
        return false;
      }
    },
    imprimirReceta() {
      this.printPrescripcion = true;
      let datos = {
        fecha_seleccionada: this.fecha_seleccionada,
        cod_informe: this.cod_informe ?? this.$route.params.id,
        nombre_cliente: this.historia_clientes_list[0].nombre_cliente,
        nombre_medico: this.historia_clientes_list[0].nombre_medico,
        nro_receta: this.recetaSelected,
      };
      this.historiaService.printPrescripcion(datos).then(() => {
        this.printPrescripcion = false;
      });
    },

    printHistoriaGeneral() {
      this.printHistoria = true;
      let datos = {
        cod_informe:
          this.cod_informe !== null && this.cod_informe !== undefined
            ? this.cod_informe
            : this.$route.params.id,
      };
      this.historiaService.printHistoriaGeneral(datos).then(() => {
        this.printHistoria = false;
      });
    },

    getDatosEcoGinecologico() {
      this.datos_eco_ginecologico = {
        id_historia: this.$route.params.id,
        cliente_id: this.historia_clientes.cliente_id,
        nombre_cliente: this.historia_clientes.nombre_cliente,
        medico_id: this.historia_clientes.medico_id,
        nombre_medico: this.historia_clientes.nombre_medico,
      };
    },
    getDatosEcoAbdominal() {
      this.datos_eco_abdominal = {
        id_historia: this.$route.params.id,
        cliente_id: this.historia_clientes.cliente_id,
        nombre_cliente: this.historia_clientes.nombre_cliente,
        medico_id: this.historia_clientes.medico_id,
        nombre_medico: this.historia_clientes.nombre_medico,
      };

      this.eco_abdominals_history = this.eco_abdominals_history.filter(
        (item) => item.cliente_id == this.historia_clientes.cliente_id
      );
    },
    getDatosEcoObstetrico() {
      this.datos_eco_obstetrico = {
        id_historia: this.$route.params.id,
        cliente_id: this.historia_clientes.cliente_id,
        nombre_cliente: this.historia_clientes.nombre_cliente,
        medico_id: this.historia_clientes.medico_id,
        nombre_medico: this.historia_clientes.nombre_medico,
      };

      this.eco_obstetricos_history = this.eco_obstetricos_history.filter(
        (item) => item.cliente_id == this.historia_clientes.cliente_id
      );
    },
    getDatosMonitoroFetal() {
      this.datos_monitoreofetal = {
        id_historia: this.$route.params.id,
        cliente_id: this.historia_clientes.cliente_id,
        nombre_cliente: this.historia_clientes.nombre_cliente,
        medico_id: this.historia_clientes.medico_id,
        nombre_medico: this.historia_clientes.nombre_medico,
      };

      this.eco_monitoreofetals_history =
        this.eco_monitoreofetals_history.filter(
          (item) => item.cliente_id == this.historia_clientes.cliente_id
        );
    },
    verReceta(nro_receta) {
      this.recetaSelected = nro_receta;
      this.list_prescripciones = this.prescripciones.filter(
        (item) => item.nro_receta == nro_receta
      );
    },
    ocultarDialogReceta() {
      this.agregarRecetaDialog = false;
    },
    agregarRecetaNew() {
      this.agregarRecetaDialog = true;
      this.datos_receta = {
        id_historia: this.historia_clientes.id,
        cliente_id: this.historia_clientes.cliente_id,
        nombre_cliente: this.historia_clientes.nombre_cliente,
        medico_id: this.historia_clientes.medico_id,
        nombre_medico: this.historia_clientes.nombre_medico,
      };
    },
    fechaSeleccionada(nueva_fecha) {
      if (this.fecha_seleccionada == nueva_fecha) {
        const filteredList = this.historia_clientes_list.filter(
          (item) => item.fecha == this.fecha_seleccionada
        );

        if (filteredList.length > 0) {
          this.historia_clientes = filteredList[filteredList.length - 1];
          this.cod_informe = this.historia_clientes.id;
        }

        this.cargarPrescripcion();
        this.list_prescripciones = [];

        this.visible = true;
      } else {
        const filteredList = this.historia_clientes_list.filter(
          (item) => item.fecha == nueva_fecha
        );

        if (filteredList.length > 0) {
          this.historia_clientes = filteredList[filteredList.length - 1];
          this.cod_informe = this.historia_clientes.id;
        }
        this.cargarPrescripcion();
        this.list_prescripciones = [];
        this.visible = false;
      }
    },
    cargarPrescripcion() {
      let datos = {
        id: this.$route.params.id,
        fecha_selected: this.historia_clientes.fecha,
        cod_informe: this.cod_informe ?? this.$route.params.id,
        cliente_id: this.historia_clientes.cliente_id,
      };

      this.historiaService.getPrescripcion(datos).then((response) => {
        this.prescripciones = response.prescripciones || [];
        this.nro_receta = response.nro_receta || [];
      });
    },
    guardarHistoria(id) {
      this.loading = true;

      let datos = {
        id: id ?? "",
        ...this.historia_clientes,
        nombre_enfermera: this.historia_clientes.nombre_enfermera ?? "",
        emergencia: this.es_emergencia ? 1 : 0,
      };
      this.$confirm.require({
        header: "CONFIRMACIÓN",
        message: this.isEnfermeria()
          ? "¿Desea guardar los SIGNOS VITALES?"
          : "¿Desea guardar la HISTORIA CLÍNICA?",
        messageStyle: "font-size: 3rem",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, Guardar",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-info p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        closable: false,
        closeOnEscape: false,
        accept: () => {
          this.historiaService.updateHistoria(datos).then((response) => {
            if (response.status == 400) {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.message,
                life: 3000,
              });
              this.loading = false;
            } else {
              this.$toast.add({
                severity: "success",
                summary: "Exito",
                detail: response.message,
                life: 3000,
              });
              /*  if (this.auth.user.name != "ENFERMERIA") {
                this.printHistoriaGeneral();
              } */
              /* this.$router.push({
                name: "fichas_consultas",
              }); */
              this.loading = false;
            }
          });
        },
        reject: () => {
          this.$toast.add({
            severity: "info",
            summary: "Información",
            detail: "No se guardo la historia",
            life: 3000,
          });
          this.loading = false;
        },
      });
    },
    noSeAtendio(id) {
      let datos = {
        id: id,
      };
      //envio un alert para confirmar si desea guardar
      this.$confirm.require({
        header: "CONFIRMACIÓN",
        message: "¿Desea marcar como NO ATENDIDO?",
        messageStyle: "font-size: 3rem",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, Marcar",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-info p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        closable: false,
        closeOnEscape: false,
        accept: () => {
          this.historiaService.noSeAtendio(datos).then((response) => {
            this.$toast.add({
              severity: "success",
              summary: "Exito",
              detail: response.message,
              life: 3000,
            });
            this.$router.push({
              name: "fichas_consultas",
            });
          });
        },
        reject: () => {
          this.$toast.add({
            severity: "info",
            summary: "Información",
            detail: "No se marco como NO ATENDIDO",
            life: 3000,
          });
        },
      });
    },
    cargarHistoria() {
      let datos = {
        id: this.$route.params.id,
      };
      this.historiaService.historiaPaciente(datos).then((response) => {
        try {
          this.historia_clientes = response.historia_clientes || [];
          this.historia_clientes_list = response.historiales_clientes || [];
          this.nro_receta = response.nro_receta || [];
          this.fechas = this.historia_clientes_list.map((item) => {
            return {
              name: item.fecha,
              value: item.fecha,
            };
          });

          // recorro las historias
          this.historia_clientes.forEach((element) => {
            this.historia_clientes = element;
          });

          // Cargo la fecha seleccionada de la historia
          this.fecha_seleccionada = this.historia_clientes.fecha ?? "";

          this.fechaSeleccionada(this.fecha_seleccionada); //cargar la primera fecha

          this.es_emergencia = this.historia_clientes.emergencia === 1;
          this.historia_clientes.reanimacion =
            this.historia_clientes.reanimacion === 1;
          this.historia_clientes.triaje = this.historia_clientes.triaje === 1;
          this.historia_clientes.observacion =
            this.historia_clientes.observacion === 1;
          this.historia_clientes.internacion =
            this.historia_clientes.internacion === 1;

          if (this.historia_clientes.es_alergico != null) {
            this.check_es_alergico =
              this.historia_clientes.es_alergico.length > 1 ? true : false;
          }
          if (this.historia_clientes.toma_medicamentos != null) {
            this.check_toma_medicamentos =
              this.historia_clientes.toma_medicamentos.length > 1
                ? true
                : false;
          }

          this.sedes = response.datos.sedes || [];
          this.enfermeras = response.datos.enfermeras || [];
          //recorro  las enfermeras y si son de igual nombre que el usuario logueado lo selecciono
          this.enfermeras.forEach((item) => {
            if (item.nombre_completo == this.auth.user.name) {
              this.historia_clientes.nombre_enfermera = item.nombre_completo;
            } else {
              //que se seleccione la primera enfermera
              this.historia_clientes.nombre_enfermera =
                this.enfermeras != null
                  ? this.enfermeras[0].nombre_completo
                  : "";
            }
          });
          this.nombre_rol_usuario = response.datos.nombre_rol_usuario ?? "";

          this.eco_abdominals_history = response.eco_abdominals_history || [];
          this.eco_ginecologicos_history =
            response.eco_ginecologicos_history || [];
          this.eco_obstetricos_history = response.eco_obstetricos_history || [];
          this.eco_monitoreofetals_history =
            response.eco_monitoreofetals_history || [];
          this.emergencias_medicas = response.emergencias_medicas || [];
          this.medicos = response.medicos || [];
          this.datos_frmprefedinidos = response.datos_frmprefedinidos || [];
          this.formulario_predefinidos = response.formulario_predefinidos || [];
          this.$toast.add({
            severity: "success",
            summary: "Exito",
            detail: response.message,
            life: 3000,
          });
        } catch (error) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: error,
            life: 3000,
          });
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #dedede;
  margin-left: 1px;
  margin-right: 1px;
  padding: 5px;
  border-radius: 5px;
}
.p-fondo2 {
  background-color: #f8f9fa;
  margin-left: 1px;
  margin-right: 1px;
  padding: 5px;
  border-radius: 5px;
}
.p-fondo3 {
  background-color: #7bffae;
  margin-left: 1px;
  margin-right: 1px;
  padding: 5px;
  border-radius: 5px;
}
.subtitulos {
  font-size: 1.3em;
  font-weight: bold;
  text-decoration: underline;
  text-align-last: center;
  color: #003a6e;
}
.tabview-custom {
  i,
  span {
    vertical-align: middle;
  }
}
.color-linea {
  border: 1px solid #003a6e;
  border-radius: 5px;
}
.p-tabview .p-tabview-nav .p-tabview-nav-link {
  font-weight: bold;
  color: #002236;
}
.estilo-titulo-tab {
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  color: #002236;
}
.p-inputgroup-addon {
  background-color: #ffffff;
  font-weight: bold;
  color: #000000;
  border: none;
}
</style>
